import ScrollReveal  from "ScrollReveal";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginIndicator} from "scrollmagic-plugins";

function init() {
  ScrollMagicPluginIndicator(ScrollMagic);

  var slideUp = {
    distance: '150%',
    origin: 'bottom',
    opacity: 0
  };

  var slideUpDelay = {
    distance: '150%',
    origin: 'bottom',
    opacity: 0,
    delay: 375,
  };


  // $('svg circle').addClass('pd-animate-circle');
  // $('svg circle').addClass('class-one');

  ScrollReveal().reveal('.slide__up', slideUp);
  ScrollReveal().reveal('.slide__up__delay', slideUpDelay);

  var controller = new ScrollMagic.Controller();

  // const panels = document.querySelectorAll('.pd-panel');
  //
  // panels.forEach(function (el) {
  //   const circle = el.querySelectorAll('.class-one');
  //   new ScrollMagic.Scene({
  //     triggerElement: el,
  //     triggerHook: 0.5, // show, when scrolled 10% into view
  //     reverse: false
  //
  //   })
  //       .setClassToggle(circle, "visible") // add class to reveal
  //       .addTo(controller);
  // });

  $('a[href^="#"], button[href^="#"]').on('click', function(e) {
    e.preventDefault();

    const scrollTop = $($(this).attr('href')).position().top - 100;

    $('html, body').animate({ scrollTop });
  });

  $('#cmp-related-tags').hide()
  $('.footer').hide();

}

window.addEventListener("load", function () {
  init();
});
